.status-container {
  display: flex;
  align-items: center;
  width: 100%;

  :last-child {
    margin: 0 4px;
    padding: 2px;
  }
}

.status {
  text-transform: capitalize;
  flex-grow: 1;
}
