@import 'styles/breakpoints';

// header button //
.btn {
  margin-left: 1.6rem;
  padding: 0.8rem 1.6rem;
  color: var(--color-text-secondary);
  border: thin solid var(--color-text-secondary);
  text-transform: uppercase;
  position: relative;
  user-select: none;
  cursor: pointer;
  transition: var(--animation-basic);

  &:hover,
  &:active {
    color: var(--color-theme-primary);
    border: thin solid var(--color-theme-primary);
  }
}

// dialog and form //
.dialog {
  :global {
    .MuiDialogTitle-root {
      text-align: center;
    }

    .MuiDialogContent-root {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 0 8rem;
      @media only screen and (max-width: $bp-md) {
        padding: 0 1.5rem;
      }
    }

    .MuiDialogActions-root {
      justify-content: center;
    }
  }
}

.form {
  padding-top: 10px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  :global {
    & .MuiFormControl-root,
    & .MuiButton-root {
      width: 100%;
      margin: 1rem;

      @media only screen and (max-width: $bp-md) {
        margin: 0.5rem;
      }
    }
  }
}
