.footer {
  height: 10rem;
  text-align: center;
  line-height: 10rem;
  background-color: var(--color-background-secondary);
  color: var(--color-text-secondary);

  &__link {
    color: var(--color-theme-primary);
    display: inline-block;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
      text-decoration-thickness: 1px;
    }
  }
}
