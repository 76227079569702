.card__label {
  padding: 0.8rem 2.4rem;
  font-size: 1.8rem;
}

.card__content {
  padding: 1.6rem 2.4rem;
}

.error {
  color: #d32f2f;
  font-size: 1.2rem;
}

.filelist {
  list-style: none;
  width: 100%;

  :global(.MuiCollapse-root) {
    margin-top: 0.8rem;

    &:first-of-type {
      margin-top: 1.6rem;
    }

    &:last-of-type {
      margin-bottom: 0.8rem;
    }
  }
}

.chip {
  /* stylelint-disable-next-line no-descending-specificity */
  :global(a) {
    height: 100%;
    max-width: 100%;
    flex-grow: 1;
    display: flex;
    align-items: center;
    text-decoration: none;
    color: var(--color-theme-primary);

    /* stylelint-disable-next-line no-descending-specificity */
    :hover {
      text-decoration: underline;
    }
    /* stylelint-disable-next-line no-descending-specificity */
    :active {
      text-decoration: underline;
    }

    /* stylelint-disable-next-line no-descending-specificity */
    :first-child {
      height: 1.6rem;
      width: 1.6rem;
      margin-right: 4px;
    }

    /* stylelint-disable-next-line no-descending-specificity */
    :last-child {
      height: 1.6rem;
      line-height: 1.6rem;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &:global(.MuiChip-root) {
    width: 60%;
    min-width: 30rem;
    border-radius: 4px;
    justify-content: space-between;

    :global(.MuiChip-label) {
      padding-top: 2px;
    }
  }
}
