.card {
  background-color: transparent;
  height: 100%;
  min-height: 3.6rem;
  display: grid;
  align-items: center;
  padding-left: 2.4rem;

  &__line {
    color: var(--color-text-accent);
    font-weight: var(--font-weight-bold);
  }
}
