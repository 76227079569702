.filelist {
  list-style: none;
  line-height: 1.5;
  width: 100%;
  max-height: 100%;
  overflow-x: hidden;
  overflow-y: auto;

  &__item {
    font-size: 1.2rem;
    overflow: hidden;
  }

  &__link {
    display: inline-flex;
    align-items: center;
    height: 100%;
    max-width: 100%;
    color: var(--color-theme-primary);
    text-decoration: none;

    &:hover,
    &:active {
      text-decoration: underline;
    }

    :first-child {
      height: 1.6rem;
      width: 1.6rem;
      padding: 0;
    }

    :last-child {
      display: inline-block;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
