@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300&display=swap');
@import 'styles/breakpoints';

.container {
  width: 100%;
  flex-grow: 1;
  display: grid;
  place-items: center;
  place-content: center;
}

.logo-container {
  margin-top: 2.4rem;
}

.logo {
  width: 24rem;
  @media only screen and (max-width: $bp-sm) {
    width: 16rem;
  }
}

.animated {
  font-size: 3.6rem;
  font-family: Quicksand, sans-serif;

  @media only screen and (max-width: $bp-sm) {
    font-size: 2.4rem;
  }

  & span {
    display: inline-block;
    color: var(--color-text-light);
    opacity: 0;

    // transform: translate(0, -300px) scale(0);
    animation: side-slide 0.5s forwards;

    &.color--0 {
      --color-animated: #543e7f;
    }

    &.color--1 {
      --color-animated: #fee23b;
    }

    &.color--2 {
      --color-animated: #dc4e3d;
    }

    &.color--3 {
      --color-animated: #f89d1c;
    }

    &.color--4 {
      --color-animated: #7b4da2;
    }

    &.color--5 {
      --color-animated: #33a5ad;
    }
  }

  @keyframes side-slide {
    20% {
      transform: translate(0, 1.6rem) scale(1);
      color: var(--color-text-light);
    }

    40% {
      transform: translate(0, 0.8rem) scale(1);
      color: var(--color-text-light);
    }

    90% {
      transform: translate(0) scale(1.1);
      color: var(--color-animated);
    }

    100% {
      transform: translate(0) scale(1);
      opacity: 1;
      color: var(--color-text-light);
    }
  }
}
