@import './breakpoints';

:root {
  // colors
  --color-black: #121212;
  --color-white: #fff;
  --color-theme-primary: #17969f;

  // themed
  --color-background-secondary: var(--color-black);
  --color-text-primary: var(--color-black);
  --color-text-secondary: var(--color-white);
  --color-text-light: #56565b;
  --color-text-accent: #afafaf;

  // research areas
  --color-area-61c6063319ef185c54f98bd1: #ffec9e;
  --color-area-61c6063319ef185c54f98bcd: #ff9bad;
  --color-area-61c6063319ef185c54f98bd2: #b5ffca;
  --color-area-61c6063319ef185c54f98bcf: #ffccab;
  --color-area-61c6063319ef185c54f98bd0: #d0a5f5;
  --color-area-61c6063319ef185c54f98bce: #7df6ff;

  // SHADOWS
  --shadow-bottom-dark: 0 0.5rem 2rem rgb(0 0 0 / 20%);
  --shadow-bottom-light: 0 0.5rem 2rem rgb(0 0 0 / 6%);
  --shadow-element-dark: 0 0 3rem rgb(0 0 0 / 15%);
  --shadow-element-light: 0 0 3rem rgb(0 0 0 / 6%);

  // FONTS
  --font-primary: 'Nunito Sans', sans-serif;
  --font-secondary: 'Roboto', sans-serif;
  --font-weight-thin: 300;
  --font-weight-normal: 600;
  --font-weight-bold: 800;

  // transition
  --animation-basic: all 0.3s;
}

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  box-sizing: border-box;
  font-family: var(--font-primary);
  font-size: 62.5%; // 10px/16px = 62.5% -> 1rem = 10px

  @media only screen and (max-width: $bp-lg) {
    font-size: 50%;
  }
}

body {
  font-size: 1.4rem;
  font-weight: 300;
}

/* stylelint-disable-next-line selector-class-pattern */
.App {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

h1 {
  font-size: 2.4rem;
}

h3 {
  font-size: 1.8rem;
}

// https://github.com/mui-org/material-ui-x/issues/417

.MuiDataGrid-viewport,
.MuiDataGrid-row,
.MuiDataGrid-renderingZone {
  max-height: fit-content !important;
}

.MuiDataGrid-cell {
  height: initial !important;
  max-height: fit-content !important;
  overflow: auto;
  white-space: initial !important;
  line-height: 16px !important;
  display: flex !important;
  align-items: center;
  padding-top: 16px !important;
  padding-bottom: 16px !important;
}

.MuiDataGrid-virtualScrollerContent {
  min-height: 80px;
}
