@import 'styles/breakpoints';

.card {
  box-shadow: var(--shadow-element-light);
  padding: 1rem 0;
  height: 100%;
  display: flex;
  flex-direction: column;

  :global(a) {
    text-decoration: none;
    color: var(--color-text-primary);

    &:hover,
    &:active {
      // color: var(--color-theme-primary);
      text-decoration: underline;
    }
  }

  &__title {
    padding: 1rem 2.4rem;
    flex-grow: 1;
  }

  &__line {
    padding: 0.5rem 2.4rem;
    display: flex;
    flex-direction: row;

    /* stylelint-disable-next-line no-descending-specificity */
    :not(:last-child) {
      margin-right: 0.5rem;
    }

    @media only screen and (max-width: $bp-md) {
      flex-direction: column;
      align-items: flex-start;

      :not(:last-child) {
        margin-bottom: 0.5rem;
      }
    }
  }
}
