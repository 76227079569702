.nav {
  list-style: none;
  display: flex;
  flex-direction: row;
  margin-bottom: 2rem;

  &__item {
    display: inline-block;
    padding: 1rem;
    text-decoration: none;
    color: var(--color-text-primary);
    border: thin solid var(--color-text-primary);

    &:not(:last-of-type) {
      border-right: none;
    }

    transition: color 0.3s;

    &:hover,
    &:active,
    &:global(.active) {
      color: var(--color-theme-primary);
      border: thin solid var(--color-theme-primary);

      & + * {
        border-left: none;
      }
    }

    &:hover {
      box-shadow: var(--shadow-element-light);
    }
  }

  &__item--level1 {
    font-size: 1.4rem;
    padding: 0.8rem;
    transition: background-color 0.3s;

    &:hover,
    &:active,
    &:global(.active) {
      color: var(--color-text-secondary);
      background-color: var(--color-theme-primary);
    }
  }
}
