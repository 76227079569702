.navlink__wrapper {
  display: flex;
  justify-content: space-between;
}

.navlink {
  text-decoration: none;
  display: flex;
  flex-direction: column;
  align-items: center;

  &__line {
    flex-grow: 1;
    border-bottom: thin solid var(--color-text-accent);
    margin-bottom: 0.8rem;
  }

  &__label {
    // text-decoration: none;
    color: var(--color-text-light);
    margin-bottom: 0.8rem;
  }

  &__icon {
    width: 1.6rem;
    height: 1.6rem;
    border-radius: 0.8rem;
    border: thin solid var(--color-theme-primary);
    background-color: transparent;
    transition: var(--animation-basic);
  }

  &:global(.active) {
    .navlink {
      &__label {
        font-weight: var(--font-weight-normal);
      }

      &__icon {
        background-color: var(--color-theme-primary);
      }
    }
  }

  /* stylelint-disable-next-line no-descending-specificity */
  &:global(.disabled) {
    pointer-events: none;

    .navlink {
      &__label {
        font-weight: 400;
        color: var(--color-text-accent);
      }

      &__icon {
        border: none;
        background-color: var(--color-text-accent);
      }
    }
  }
}
