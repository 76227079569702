@import 'styles/breakpoints';

.header {
  position: sticky;
  height: 8rem;
  padding: 0 8rem;
  background-color: var(--color-background-secondary);
  display: flex;
  flex-direction: row;
  align-items: center;

  @media only screen and (max-width: $bp-md) {
    padding: 0 2rem;
  }

  &__logo {
    flex: 1;

    :global(img) {
      height: 4.8rem;
    }
  }

  &__link {
    padding: 0.8rem 1.6rem;
    color: var(--color-text-secondary);
    text-decoration: none;
    text-transform: uppercase;
    position: relative;

    &::before,
    &::after {
      position: absolute;
      opacity: 0;
      width: 0%;
      height: 1px;
      content: '';
      background: var(--color-text-secondary);
      transition: var(--animation-basic);
    }

    &::before {
      left: 0;
      top: 0;
    }

    &::after {
      right: 0;
      bottom: 0;
    }

    &:hover,
    &:active {
      &::before,
      &::after {
        opacity: 1;
        width: 100%;
        background-color: var(--color-theme-primary);
      }
    }

    &:global(.active)::after,
    &:global(.active)::before {
      opacity: 1;
      width: 100%;
      background: var(--color-text-secondary);
    }
  }
}

.menu-icon {
  color: var(--color-text-secondary);
}

.menu {
  :global(a) {
    text-decoration: none;
    color: var(--color-text-primary);

    &:global(.active) {
      color: var(--color-theme-primary);
    }
  }

  /* stylelint-disable-next-line no-descending-specificity */
  :global(.MuiPaper-root) {
    border-radius: 0;
  }
}
